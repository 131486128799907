import * as React from "react";
import "../styles/styles.css";

import Footer from "../components/Footer";
import Header from "../components/Header";
import HeroSection from "../components/HeroSection";
import Description from "../components/Description";
import { Helmet } from "react-helmet";

import { descriptionInfo } from "../consts/Description";
import GeneralInfo from "../components/GeneralInfo";
import Map from "../components/Map";
import PetSection from "../components/PetSection";
import CommonFood from "../components/CommonFood";

// markup
const IndexPage = () => {
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" name="House 5" description="House 5" />
        <title>House 5</title>
      </Helmet>
      <Header />
      <HeroSection />
      <a id="about">
        <GeneralInfo />
      </a>
      <CommonFood />
      {descriptionInfo.map((value, index) => {
        return <Description info={value} number={index} key={index} />;
      })}
      <PetSection />
      <a id="location">
        <Map />
      </a>
      <a id="contact">
        <Footer />
      </a>
    </main>
  );
};

export default IndexPage;
