import React from "react";
import styled from "styled-components";
import { PALETTE } from "../consts/Colors";
import MockUp from "../images/mockup.jpg";

export default function HeroSection() {
  return (
    <HeroContainer>
      <MockImg src={MockUp}></MockImg>
      {/* <InfoDiv>LALITPUR</InfoDiv> */}
    </HeroContainer>
  );
}

const HeroContainer = styled.div`
  background-color: ${PALETTE.HEADER_BG};
`;

const MockImg = styled.img`
  width: 100%;
  height: auto;
  z-index: 1;
  overflow: hidden;
`;

// const InfoDiv = styled.div`
//   position: absolute;
//   bottom: 10;
//   right: 10;
//   z-index: 2;
// `;
