import React from "react";
import styled from "styled-components";
import { PALETTE } from "../consts/Colors";
import { BevLogo } from "../consts/SVG";

export default function GeneralInfo() {
  return (
    <GeneralInfoContainer>
      <Logo>{BevLogo}</Logo>
      <Title>The HOUSE 5 Story</Title>
      <Description>
        Situated at the heart of Lalitpur, our address is 'घर ५, थाडो धुंगा,
        ललितपुर', hence the name of our cafe, HOUSE 5. We are obsessively
        passionate about using the freshest produce and handmade delicacies. Our
        menu has been lovingly crafted bringing together the best of ingredients
        and a legendary brunch experience. Aside from brunch, HOUSE 5 Cafe
        also serves speciality lunch items that ranges from pastas, salads,
        burgers, wraps and sandwiches.
      </Description>
    </GeneralInfoContainer>
  );
}

const GeneralInfoContainer = styled.div`
  padding: 80px 80px;
  background-color: ${PALETTE.HEADER_BG};
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-margin-top: 100px;
  justify-content: center;
  @media (max-width: 600px) {
    padding: 30px 10px;
  }
`;

const Logo = styled.div`
  padding-bottom: 60px;
  &:hover {
    animation: beat 0.5s infinite alternate;
    @keyframes beat {
      to {
        transform: scale(1.1);
      }
    }
  }
  @media (max-width: 600px) {
    padding-bottom: 30px;
  }
`;
const Title = styled.div`
  width: 40%;
  display:flex;
  justify-content: center;
  text-align: justify;
  text-justify: inter-word;
  font-size: 2.25em;

  font-weight: 400;
  line-height: 200%;
  color: ${PALETTE.BROWN_TEXT};
`;
const Description = styled.div`
  width: 70%;
  text-align: justify;
  text-justify: inter-word;
  font-size: 1.25em;

  font-weight: 400;
  line-height: 200%;
  color: ${PALETTE.BROWN_TEXT};

  @media (max-width: 600px) {
    width: 90%;
  }
`;
