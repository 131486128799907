import Illy1 from "../images/true-artisan.jpg";
import Illy2 from "../images/illy-coffee.jpg";
import Illy3 from "../images/coffee-love.jpg";

export const descriptionInfo = [
  {
    title: "True Artisans Cafe",
    explain:
      "House 5 welcomes you to the True Artisans Cafe experience. La Marzocco had its beginnings in Florence, Italy, home to some of the most celebrated works of art in the world. La Marzocco holds a world renown reputation for making beautiful, high quality, superbly crafted and uniquely designed espresso machines, with great attention to detail.",
    image: Illy1,
  },
  {
    title: "Our love for coffee",
    explain:
      "House 5 offers a unique coffee blend of nine different types of Arabica. A symphony that creates a unique flavour: a perfect balance between sour and bitter taste, a very broad aromatic profile, a prolonged aftertaste.",
    image: Illy2,
  },
  {
    title: "Coffee, Eats and Treats",
    explain:
      "The textured white walls and copper accents create a decadent atmosphere in which you can enjoy the vibrant and colourful cuisine critically acclaimed by many. Mixing Italian traditions with innovative techniques, HOUSE 5 presents a modern cuisine with Gourmet Sandwiches, Pasta and Sweet Treats.",
    image: Illy3,
  },
];
