import React from "react";
import styled from "styled-components";
import Left from "../images/pet-left.jpg";
import Right from "../images/pet-right.jpg";
import { BOX_SHADOW, PALETTE } from "../consts/Colors";

export default function PetSection() {
  const title = "Your Local Green Oasis";
  const description =
    "Take a wander & explore our green oasis when you visit. At HOUSE 5, you will be welcomed with thoughtfully detailed interiors, lush surroundings, friendly relaxed service and zen environment. HOUSE 5 Cafe is the place for hearty brunches and lazy afternoon teas, romantic dates and friendly celebrations in beautiful surroundings. Our architecture is inspired by the Japandi design which is the combination of Scandinavian functionality and Japanese rustic minimalism to create a feeling of art, nature, and simplicity. This fusion creates the perfect blend of function and form, highlighting focus on bright spaces, and light colors.";
  return (
    <PetContainer>
      <PetImg src={Left} />
      <CenterText>
        <PetTitle>{title}</PetTitle>
        <PetDescription>{description}</PetDescription>
      </CenterText>
      <PetImg src={Right} />
    </PetContainer>
  );
}

const PetContainer = styled.div`
  position: relative;
  padding: 80px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${PALETTE.DARK_GREEN};
  background-color: ${PALETTE.CREAM};
  @media (max-width: 600px) {
    padding: 30px 10px;
    flex-direction: column;
  }
`;

const PetImg = styled.img`
  width: 100%;
  @media (max-width: 1200px) {
    display: none;
  }
`;

const CenterText = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  height: 95%;
  width: 35%;
  background-color: #e6e1d3;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 1200px) {
    position: static;
    width: 100%;
  }

  &:hover {
    box-shadow: ${BOX_SHADOW};
  }
`;

const PetTitle = styled.div`
  padding: 30px 40px 20px 40px;
  margin-left: auto;
  margin-right: auto;
  font-size: 3em;
  font-weight: 700;
  font-family: "Cedarville Cursive", cursive;
  text-align: center;
  @media (max-width: 600px) {
    padding: 30px 10px;
    font-size: 32px;
    font-weight: 500;
  }
`;

const PetDescription = styled.div`
  padding: 30px 40px 20px 40px;
  font-size: 1.25em;
  font-weight: 500;
  line-height: 200%;
  text-align: justify;
  text-justify: inter-word;

  @media (max-width: 600px) {
    padding: 30px 10px;
    font-size: 16px;
    font-weight: 300;
  }
`;
