import React from "react";
import styled from "styled-components";
import { BOX_SHADOW, PALETTE } from "../consts/Colors";
import { EggIcon, CoffeeIcon, CakeIcon } from "../consts/SVG";

export default function CommonFood() {
  return (
    <CommonFoodContainer>
      <SingleItem>{CoffeeIcon}</SingleItem>
      <SingleItem>{EggIcon}</SingleItem>
      <SingleItem>{CakeIcon}</SingleItem>
    </CommonFoodContainer>
  );
}

const CommonFoodContainer = styled.div`
  padding: 160px 80px;
  background-color: ${PALETTE.BROWN};
  display: flex;
  flex-direction: row;
  align-items: center;
  scroll-margin-top: 100px;
  justify-content: center;
  @media (max-width: 600px) {
    padding: 30px 10px;
    flex-direction: column;
  }
`;

const SingleItem = styled.div`
  margin-right: 60px;
  text-shadow: ${BOX_SHADOW};
  &:hover {
    animation: beat 0.5s infinite alternate;
    @keyframes beat {
      to {
        transform: scale(1.1);
      }
    }
  }
  @media (max-width: 600px) {
    margin-right: 0px;
    margin-bottom: 20px;
  }
`;
