import React from "react";
import styled from "styled-components";
import { PALETTE, BOX_SHADOW } from "../consts/Colors";

export default function Description(props) {
  const descriptionTitle = (
    <DescriptionTitle>{props.info.title}</DescriptionTitle>
  );

  const descriptionBody = (
    <DescriptionBody>{props.info.explain}</DescriptionBody>
  );

  const isEven = props.number % 2 === 0;

  if (isEven) {
    return (
      <DescriptionContainer number={isEven}>
        <SingleDescription>
          <CoffeeImg src={props.info.image} />
        </SingleDescription>
        <SingleDescription>
          {descriptionTitle} {descriptionBody}
        </SingleDescription>
      </DescriptionContainer>
    );
  }

  return (
    <DescriptionContainer number={isEven}>
      <SingleDescription>
        {descriptionTitle} {descriptionBody}
      </SingleDescription>
      <SingleDescription>
        <CoffeeImg src={props.info.image} />
      </SingleDescription>
    </DescriptionContainer>
  );
}

const DescriptionContainer = styled.div`
  padding: 80px 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: ${PALETTE.DARK_GREEN};
  background-color: ${(props) =>
    props.number ? PALETTE.BACKGROUND : PALETTE.HEADER_BG};
  @media (max-width: 600px) {
    padding: 30px 10px;
    flex-direction: column;
  }
`;

const SingleDescription = styled.div`
  width: 50%;
  padding-right: 80px;
  @media (max-width: 600px) {
    width: 100%;
    padding: 10px 10px;
  }
`;

const DescriptionTitle = styled.div`
  font-size: 2rem;
  font-weight: 700;
  padding-bottom: 20px;
`;

const DescriptionBody = styled.div`
  font-size: 1.25em;
  font-weight: 400;
  line-height: 150%;
  text-align: justify;
  text-justify: inter-word;
  color: ${PALETTE.BROWN_TEXT};
`;

const CoffeeImg = styled.img`
  width: 100%;
  &:hover {
    box-shadow: ${BOX_SHADOW};
  }
`;
